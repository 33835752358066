$first-color: #248DF9;
$second-color: #43ADFF;
$hitam: #000;
$putih: #fff;
$merah: #D9534F;
$hijau: #5CB85C;

@mixin border-radius($radius){
	border-radius:$radius;
	-moz-border-radius:$radius;
	-webkit-border-radius:$radius;
	-o-border-radius:$radius;
	-ms-border-radius:$radius;
}
@mixin item-transform($value){
	transform:$value;
	-moz-transform:$value;
	-webkit-transform:$value;
	-o-transform:$value;
	-ms-transform:$value;
}
@mixin tombol-transition($transition){
	transition: $transition; 
	-webkit-transition: $transition; 
	-moz-transition: $transition; 
	-o-transition: $transition; 
	-ms-transition: $transition; 
}
%shared-text-page {
	font: normal 13px "Open Sans", calibri;
	border: 1px solid transparent;
	margin: 4px;
	padding: 4px 8px;
}
%shared-judul{
	// color: lighten($hitam, 10%);
	text-decoration: none;
	// text-shadow: 0px 0px 0px #fff;
	font-family: "Roboto", "Open Sans", "Monteros", "Times New Roman", "PT Serif", Oswald, Belleza, Raleway, "PT Sans", Arial, sans-serif, "Dosis", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", Helvetica;
	font-weight: normal;
}
%shared-tombol-shadow{
	box-shadow: 0 0 1px 1px darken($putih, 10%); 
	-moz-box-shadow: 0 0 1px 1px darken($putih, 10%); 
	-webkit-box-shadow: 0 0 1px 1px darken($putih, 10%); 
	-o-box-shadow: 0 0 1px 1px darken($putih, 10%); 
	-ms-box-shadow: 0 0 1px 1px darken($putih, 10%); 
}
%shared-box-sizing {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
%shared-news {
	font-family: Roboto;
	text-align: left; 
	color: lighten($hitam, 25%);
}
%shared-kat{
	color: lighten($hitam, 25%);
	text-transform: capitalize;
	display: block;
}
