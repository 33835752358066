/* jssor slider arrow navigator skin 05 css */
/*
.jssora05l                  (normal)
.jssora05r                  (normal)
.jssora05l:hover            (normal mouseover)
.jssora05r:hover            (normal mouseover)
.jssora05l.jssora05ldn      (mousedown)
.jssora05r.jssora05rdn      (mousedown)
*/
/* jssor slider thumbnail navigator skin 02 css */
/*
.jssort02 .p            (normal)
.jssort02 .p:hover      (normal mouseover)
.jssort02 .p.pav        (active)
.jssort02 .p.pdn        (mousedown)
*/
.jssora05l {
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	cursor: pointer;
	background: url('../../js/jssor/img/a17.png') no-repeat;
	overflow: hidden;
	background-position: -10px -40px;
	&:hover {
		background-position: -130px -40px;
	}
}
.jssora05r {
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	cursor: pointer;
	background: url('../../js/jssor/img/a17.png') no-repeat;
	overflow: hidden;
	background-position: -70px -40px;
	&:hover {
		background-position: -190px -40px;
	}
}
.jssora05l.jssora05ldn {
	background-position: -250px -40px;
}
.jssora05r.jssora05rdn {
	background-position: -310px -40px;
}
.jssort02 {
	position: absolute;
	width: 120px;
	height: 480px;
	.p {
		position: absolute;
		top: 0;
		left: 0;
		width: 99px;
		height: 96px;
		&:hover {
			.c {
				top: 0px;
				left: 0px;
				width: 97px;
				height: 94px;
				border: #000 1px solid;
				background-position: 50% 50%;
			}
		}
	}
	.t {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
	.w {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.c {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 95px;
		height: 92px;
		border: #ddd 2px solid;
		box-sizing: content-box;
		background: url('../../js/jssor/img/t01.png') -800px -800px no-repeat;
		_background: none;
	}
	.pav {
		.c {
			top: 2px;
			_top: 0px;
			left: 2px;
			_left: 0px;
			width: 95px;
			height: 92px;
			border: #ddd 0px solid;
			_border: #fff 2px solid;
			background-position: 50% 50%;
		}
	}
	.p.pdn {
		.c {
			background-position: 50% 50%;
			width: 95px;
			height: 92px;
			border: #000 2px solid;
		}
	}
}
* {
	html {
		.jssort02 {
			.c {
				width: 99px;
				height: 96px;
			}
			.pdn {
				.c {
					width: 99px;
					height: 96px;
				}
			}
			.pav {
				.c {
					width: 99px;
					height: 96px;
				}
			}
		}
	}
}
.captionOrange, .captionBlack { 
	color: #fff; 
	font-size: 20px; 
	line-height: 30px; 
	text-align: center; 
	border-radius: 4px;
}
.captionOrange { 
	background: #EB5100; 
	background-color: rgba(235, 81, 0, 0.6);
}
.captionBlack { 
	font-size:16px; 
	background: #000; 
	background-color: rgba(0, 0, 0, 0.4);
}
a.captionOrange, A.captionOrange:active, A.captionOrange:visited { 
	color: #ffffff; 
	text-decoration: none;
}
a.captionOrange:hover { 
	color: #eb5100; 
	text-decoration: underline; 
	background-color: #eeeeee; 
	background-color: rgba(238, 238, 238, 0.7);
}
.bricon { 
	background: url('../../js/jssor/img/browser-icons.png');
}
