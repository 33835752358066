﻿/************************************************************************************
MEDIA QUERIES
trick : width auto || 100%; float:none
width < 100% float:left
*************************************************************************************/
#top1{position:fixed}
#top2{ padding:140px 0 0 0}
#isi_kiri { width: 20%; float:left}
#isi_kanan { width: 80%; float:left}
#isi_kiri_alt { width: 66.66%; float:left}
#isi_tengah_alt { width: 33.33%; float:left}
/*#sidebar { width: 20%; float:left; padding:0 8px 0 0}*/
.top_logo, .top_kiri { text-align:left}
.top_kanan { text-align:right}
.sw-navcart { width:12.5%; float:left; display:inline-block}
.sw-2cols { width:16.66%; float:left; display:inline-block}
.sw-2cols-alt { width:20%; float:left; display:inline-block}
.sw-3cols { width:25%; float:left; display:inline-block}
.sw-3cols-alt { width:25%; float:left; display:inline-block}
.sw-4cols { width:33.33%; float:left; display:inline-block}
.sw-4cols-alt { width:33.33%; float:left; display:inline-block; padding:0 8px 0 0}
.sw-4cols-alt2 { width:33.33%; float:left; display:inline-block; padding:0 8px 0 0}
.sw-6cols { width:50%; float:left; display:inline-block}
.sw-6cols-alt { width:50%; float:left; display:inline-block; padding:0 8px 0 0}
.sw-8cols { width:66.66%; float:left; display:inline-block}
.sw-8cols-alt { width:66.66%; float:left; display:inline-block; padding:0 8px 0 0}
.sw-9cols { width:75%; float:left; display:inline-block}
.sw-9cols-alt { width:75%; float:left; display:inline-block}
.sw-10cols { width:83.33%; float:left; display:inline-block}
.sw-12cols { width:100%; float:none; display:inline-block}
.icon-menu { text-align:center}
.text-hp{}
.img-resp { width:100%}
.img-resp-left { max-height:100%; width:50%; float:left; padding:8px}
.img-resp-left-alt { 
	/*max-height:; */
	width:33.33%; 
	float:left
}
.img-resp-right { max-height:100%; width: 33.33%; float:right; padding:8px}
.img-detail { width:50%; padding:8px}
.flying_div { 
	/*display:*/
}
.shape_trend { display:block}
.menutop_fixed { display:block}
.menutop_scroll { display:none}
.header {display:none}
#container-lg{display:block}
#container-sm{display:none}
.frame {background:#fff; padding:1%; background-color:rgba(255, 255, 255, 0.5); width:90%}
.my-account { display:none}
.my-account-2 { display:block}
.slider-menuweb, #prod-detail {
	display: block;
	visibility: visible;
}
.slider-menuweb-mobile, #prod-detail-mobile {
	display: none;
	visibility: hidden;
}

/* Jika berukuran 980px atau kurang*/
@media only screen and (max-width: 1024px) {
    #pagewrap, #isi_in, #top_in1, #top_in2, #top_in3, #bottom_in1, #bottom_in2, #bottom_in3, .top_in_alt {
        width: 95%;
    }
	#isi_tengah {
		width:100%;
    }
/*	#isi_kiri { width: 75%; float:left}
	#isi_kanan { width: 25%; float:left}*/
	#isi_kiri_alt { width: 66.66%; float:left}
	#isi_tengah_alt { width: 33.33%; float:left}
/*	#sidebar { width: 25%; float:left; padding:0 0 0 8px }*/
	.top_logo, .top_kiri { text-align:left}
	.top_kanan { text-align:right}
	.sw-2cols { width:16.66%; float:left; display:inline-block}
	.sw-2cols-alt { width:25%; float:left; display:inline-block}
	.sw-3cols { width:25%; float:left; display:inline-block}
	.sw-3cols-alt { width:25%; float:left; display:inline-block}
	.sw-4cols { width:33.33%; float:left; display:inline-block}
	.sw-4cols-alt { width:33.33%; float:left; display:inline-block; padding:0 8px 0 0}
	.sw-6cols { width:50%; float:left; display:inline-block}
	.sw-6cols-alt { width:50%; float:left; display:inline-block; padding:0 8px 0 0}
	.sw-8cols { width:66.66%; float:left; display:inline-block}
	.sw-8cols-alt { width:66.66%; float:left; display:inline-block; padding:0 8px 0 0}
	.sw-9cols-alt { width:75%; float:left; display:inline-block}
	.sw-10cols { width:83.33%; float:left; display:inline-block}
	.img-resp { width:100%}
	.img-resp-left { max-height:100%; width:50%; float:left; padding:8px}
	.img-resp-left-alt { 
		/*max-height:; */
		width:33.33%; 
		float:left
	}
	.flying_div { 
		/*display:*/
	}
	.shape_trend { display:none}
}
 
/* Jika berukuran 700px atau kurang*/
@media only screen and (max-width: 768px) {
	#top1{position:relative; z-index:0; margin:40px 0 0 0; display:none}
	#top2{ padding:40px 0 0 0}
	#top_address{display:none}
	.text-slide-up { 
		display:none;
		/*visibility:*/
	}
	#isi_kiri, #isi_kanan, .gallery, .index_cols, .border, #demo_container {
        width: auto; float: none;
    }
	#isi_kiri_alt { width: 66.66%; float:left}
	#isi_tengah_alt { width: 33.33%; float:left}
    #sidebar { width:100%; float: none; padding:0; display:none}
	.top_logo, .top_kiri { text-align:center}
	.top_logo { zoom:0.6; transform:scale(0.6);-moz-transform:scale(0.6);-webkit-transform:scale(0.6);-o-transform:scale(0.6);-ms-transform:scale(0.6); text-align:center; display:none}
	.top_kanan { text-align:center}
	.sw-2cols { width:100%; float:none; display:inline-block}
	.sw-2cols-alt { width:50%; float:none; display:inline-block}
	.sw-3cols { width:50%; float:left; display:inline-block}
	.sw-3cols-alt { width:100%; float:none; display:inline-block}
	.sw-4cols { width:50%; float:left; display:inline-block}
	.sw-4cols-alt { width:50%; float:left; display:inline-block; padding:0 8px 0 0}
	.sw-4cols-alt2 { width:100%; float:none; display:inline-block}
/*	.sw-6cols { width:100%; float:none; display:inline-block}*/
	.sw-6cols-alt { width:100%; float:none; display:inline-block; padding:0}
	.sw-8cols, sw-8cols-alt { width:100%; float:none; display:inline-block}
	.sw-9cols { width:50%; float:left; display:inline-block}
	.sw-9cols-alt { width:100%; float:left; display:inline-block}
	.sw-10cols { width:100%; float:none; display:inline-block}
	.icon-menu { zoom:0.8; transform:scale(0.8);-moz-transform:scale(0.8);-webkit-transform:scale(0.8);-o-transform:scale(0.8);-ms-transform:scale(0.8); text-align:center}
	.text-hp{ display:none}
	.img-resp { width:100%}
	.img-resp-left { 
		max-height:100%; 
		width:50%; 
		float:left; 
		/*display:*/
	}
	.img-resp-left-alt { 
		/*max-height:; */
		width:33.33%; 
		float:left
	}
	.img-resp-right { max-height:100%; width: 33.33%; float:right; display: block;}
	.flying_div { display:none}
	.shape_trend { display:none}
	.menutop_fixed { display:none; visibility:hidden}
/*	.menutop_scroll { display:block}*/
	.header {display:block}
	#container-lg{display:none}
	#container-sm{display:block}
	#menu-category{display:none}
	.my-account { display:block}
	.my-account-2 { display:none}
	.top-filter{ display:none}
}
 
/* Jika berukuran 480px atau kurang*/
@media only screen and (max-width: 480px) {
	#slider1_container{display:none}
	.text-slide-up { 
		display:none;
		/*visibility:*/
	}
    #header {
        height: auto;
    }
    h1 {
        font-size: 24px;
    }
	.judul {font-size:14px}
	#isi_kiri_alt { width: 100%; float:none}
	#isi_tengah_alt { width: 100%; float:none}
/*    #sidebar { width: auto; float: none; display:none}*/
	.top_kiri { text-align:center}
/*	.top_logo { zoom:0.6; transform:scale(0.6);-moz-transform:scale(0.6);-webkit-transform:scale(0.6);-o-transform:scale(0.6);-ms-transform:scale(0.6); text-align:center}*/
	.top_kanan { text-align:center}
	.sw-2cols { width:100%; float:none; display:inline-block}
/*	.sw-2cols-alt { width:100%; float:none; display:inline-block}*/
	.sw-3cols { width:100%; float:none; display:inline-block}
	.sw-3cols-alt { width:100%; float:none; display:inline-block}
	.sw-4cols { width:100%; float:none; display:inline-block}
	.sw-4cols-alt { width:100%; float:none; display:inline-block; padding:0}
	.sw-6cols { width:100%; float:none; display:inline-block}
	.sw-6cols-alt { width:100%; float:none; display:inline-block; padding:0}
	.sw-8cols, sw-8cols-alt { width:100%; float:none; display:inline-block}
	.sw-9cols { width:100%; float:none; display:inline-block}
	.sw-9cols-alt { width:100%; float:left; display:inline-block}
	.sw-10cols { width:100%; float:none; display:inline-block}
	.img-resp { width:100%}
	.img-resp-left { 
		max-height:100%; 
		width:100%; 
		float:left; 
		padding:0; 
		/*display:*/
	}
	.img-resp-left-alt { 
		/*max-height:; */
		width:33.33%; 
		float:left; 
		display:none
	}
	.img-resp-right { max-height:100%; width:100%; float:right; padding:0; display: block;}
	.img-detail { width:100%; padding:0}
	.flying_div { display:none}
	.shape_trend { display:none}
/*	.slider_jssor { display:none; visibility:hidden}*/
}
