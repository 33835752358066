#cart-flying-div{
  display: none;
}
.cart-bounce {
  animation: bounce 2s infinite; 
  -webkit-animation: bounce 2s infinite; 
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  0%, 25%, 50%, 75%, 100% {-webkit-transform: translateY(0);} 
  40% {transform: translateY(-12px);}
  60% {transform: translateY(-12px);}
}
@-moz-keyframes bounce {
  0%, 25%, 50%, 75%, 100% {-moz-transform: translateY(0);}
  40% {transform: translateY(-12px);}
  60% {transform: translateY(-12px);}
}
@-o-keyframes bounce {
  0%, 25%, 50%, 75%, 100% {-o-transform: translateY(0);}
  40% {transform: translateY(-12px);}
  60% {transform: translateY(-12px);}
}
@keyframes bounce {
  0%, 25%, 50%, 75%, 100% {transform: translateY(0);}
  40% {transform: translateY(-12px);}
  60% {transform: translateY(-12px);}
}

@keyframes bengkak1 {
  0% { transform: scale(1); -webkit-transform: scale(1); }
  5% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  10% { transform: scale(1); -webkit-transform: scale(1); }
  15% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  20% { transform: scale(1); -webkit-transform: scale(1); }
  100% { transform: scale(1); -webkit-transform: scale(1); }
}
@-webkit-keyframes bengkak1 {
  0% { transform: scale(1); -webkit-transform: scale(1); }
  5% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  10% { transform: scale(1); -webkit-transform: scale(1); }
  15% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  20% { transform: scale(1); -webkit-transform: scale(1); }
  100% { transform: scale(1); -webkit-transform: scale(1); }
}
@keyframes bengkak2 {
  0% { transform: scale(1); -webkit-transform: scale(1); }
  20% { transform: scale(1); -webkit-transform: scale(1); }
  25% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  30% { transform: scale(1); -webkit-transform: scale(1); }
  35% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  40% { transform: scale(1); -webkit-transform: scale(1); }
  100% { transform: scale(1); -webkit-transform: scale(1); }
}
@-webkit-keyframes bengkak2 {
  0% { transform: scale(1); -webkit-transform: scale(1); }
  20% { transform: scale(1); -webkit-transform: scale(1); }
  25% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  30% { transform: scale(1); -webkit-transform: scale(1); }
  35% { transform: scale(1.1); -webkit-transform: scale(1.1); }
  40% { transform: scale(1); -webkit-transform: scale(1); }
  100% { transform: scale(1); -webkit-transform: scale(1); }
}
.bengkak1, .icon-send-message {
    box-sizing: border-box;
    transform: translatey(0px);
    animation: bengkak1 4s ease-in-out infinite;
    -webkit-animation: bengkak1 4s ease-in-out infinite;
    img { width: 100%; height: auto; }
}
.bengkak2 {
  animation: bengkak2 4s ease-in-out infinite; -webkit-animation: bengkak2 4s ease-in-out infinite;
}

a{text-decoration:none;}
/*.animated {
  -webkit-animation-duration:5s;
  -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 2s;
    -webkit-animation-iteration-count: infinite;
  -moz-animation-duration:5s;
  -o-animation-duration:5s;
  animation-duration:5s;
  -webkit-animation-fill-mode:both;
  -moz-animation-fill-mode:both;
  -o-animation-fill-mode:both;
  animation-fill-mode:both;
  animation-delay: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}*/
.animated.swing {
  -webkit-transform-origin:top center;
  -moz-transform-origin:top center;
  -o-transform-origin:top center;
  transform-origin:top center;
  -webkit-animation-name:swing;
  -moz-animation-name:swing;
  -o-animation-name:swing;
  animation-name:swing;
}
 @-webkit-keyframes swing {
20%, 40%, 60%, 80%, 100% {
-webkit-transform-origin:top center
}
20% {
-webkit-transform:rotate(10deg)
}
40% {
-webkit-transform:rotate(-10deg)
}
60% {
-webkit-transform:rotate(5deg)
}
80% {
-webkit-transform:rotate(-5deg)
}
100% {
-webkit-transform:rotate(0deg)
}
}
@-moz-keyframes swing {
20% {
-moz-transform:rotate(15deg)
}
40% {
-moz-transform:rotate(-10deg)
}
60% {
-moz-transform:rotate(5deg)
}
80% {
-moz-transform:rotate(-5deg)
}
100% {
-moz-transform:rotate(0deg)
}
}
@-o-keyframes swing {
20% {
-o-transform:rotate(15deg)
}
40% {
-o-transform:rotate(-10deg)
}
60% {
-o-transform:rotate(5deg)
}
80% {
-o-transform:rotate(-5deg)
}
100% {
-o-transform:rotate(0deg)
}
}
@keyframes swing {
20% {
transform:rotate(15deg)
}
40% {
transform:rotate(-10deg)
}
60% {
transform:rotate(5deg)
}
80% {
transform:rotate(-5deg)
}
100% {
transform:rotate(0deg)
}
}
.btn-swing {
  color:#fff; /*background-color:#456ecb;
  background-image:-webkit-gradient(linear, left 0, left 100%, from(#456ecb), to(#2851ae));
  background-image:-webkit-linear-gradient(top, #456ecb, 0%, #2851ae, 100%);
  background-image:-moz-linear-gradient(top, #456ecb 0, #2851ae 100%);
  background-image:linear-gradient(to bottom, #456ecb 0, #2851ae 100%);*/
  background-repeat:repeat-x;
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff456ecb', endColorstr='#ff2851ae', GradientType=0);
  /*border-color:#2851ae; border-bottom-color:#357935; */-webkit-box-shadow:none; box-shadow:none; text-shadow:none
}
.btn-swing:hover, .btn-swing:focus, .btn-swing:active, .btn-swing.active{
  color:#fff; /*background-color:#47a447; border-color:#135185*/
}
.btn-swing:active {
  background-image:none
}
.btn {
/*  display: inline-block; padding: 12px 30px; margin-bottom: 0; font-size: 19px; font-weight: normal;
  text-align: center; vertical-align: middle; cursor: pointer; border: 1px solid transparent; border-radius: 4px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;*/
}

// @import url(http://weloveiconfonts.com/api/?family=fontawesome);
/*.fa {
 width: 60px; display: block; text-align: center; color:#990000; font:normal 45px 'FontAwesome'; line-height:60px; text-rendering: auto; -webkit-font-smoothing: antialiased;
}*/
.fa-angle-double-down:before {content: "\f103";}
.bounce {
  /*position: absolute; bottom: 30px; left: 50% ; margin-left:-30px; */
  width: 60px; height: 60px ; border: 0px solid #990000; 
  @include border-radius(50%);
  animation: bounce 2s infinite; -webkit-animation: bounce 2s infinite; -moz-animation: bounce 2s infinite;-o-animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
  40% {-moz-transform: translateY(-30px);}
  60% {-moz-transform: translateY(-15px);}
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
  40% {-o-transform: translateY(-30px);}
  60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
