﻿body { overflow-x: hidden}
body,table,tr,td,th,form,div{
	border-collapse: collapse; 
	font: normal 13px 'Nunito', Rubik, Roboto, "Open Sans", verdana, Helvetica, Arial, sans-serif, "PT Sans", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", "Source Sans Pro"; 
	margin: 0 auto;
}
div { margin:0 auto; box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;-o-box-sizing:border-box;-ms-box-sizing:border-box;}
p span, p, ul li, ol li, table tr td, table tr th{}
.bg{overflow-y:scroll; overflow-x:hidden;  /*background-size: 100% 100%;*/}
#main_bg{width:100%;height:100%;text-align:center;background:url(images/bg.jpg-) repeat-x;position:absolute;top:300px;z-index:-1}
#top{width:100%; border:0px solid #000; padding:0;  /*box-shadow:0 0 20px 1px #000;-moz-box-shadow:0 0 20px 1px #000;-webkit-box-shadow:0 0 20px 1px #000;-o-box-shadow:0 0 20px 1px #000;-ms-box-shadow:0 0 20px 1px #000;*/}
#top1{
	/*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1B1B1B), to(#010101)); background: -webkit-linear-gradient(top, #1B1B1B, #010101); background: -moz-linear-gradient(top, #1B1B1B, #010101); background: -ms-linear-gradient(top, #1B1B1B, #010101); background: -o-linear-gradient(top, #1B1B1B, #010101);*/ 
	border:0px solid #f00; 
	background:transparent;
	width:100%; 
	z-index:2/*510*/; 
	margin:0; 
	padding:0
}
.top-blue {
	background: $first-color;
	padding: 8px;		
	div {
	}
}
#top2{border:0px solid #000; }
#top3{background:url('../../images/bgtrans1.png') repeat; border:0px solid #000}
#top_in1{width:1080px; margin:0px auto; padding:0; border:0px solid #f00; }
#top_in2{width:1080px; margin:0 auto; padding:0; border:1px solid #f00}
#top_in3{width:1080px; margin:0 auto; padding:0; border:1px solid #f00}
.top_alt{width:100%;border:0px solid #000}
.top_in_alt{
	text-align: center; 
	border: 0px solid #000; 
}
#isi{width:100%; margin:0 auto; border:0px solid #000; background:  #fff; /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#FDFAF6)); background: -webkit-linear-gradient(top, #fff, #FDFAF6); background: -moz-linear-gradient(top, #fff, #FDFAF6); background: -ms-linear-gradient(top, #fff, #FDFAF6); background: -o-linear-gradient(top, #fff, #FDFAF6); */-webkit-animation: backgroundScroll 60s linear infinite; animation: backgroundScroll 60s linear infinite;}
#isi2{width:100%; margin:0 auto; border:0px solid #000; background:url(images/bg2.png) repeat}
#isi3{width:100%; margin:0 auto; border:0px solid #000}
#isi_in{text-align:center; border:0px solid #c00; padding:12px 0;  /*border-radius:5px;-moz-border-radius:5px;-webkit-border-radius:5px;-o-border-radius:5px;-ms-border-radius:5px; box-shadow:0 0 20px 0 #000;-moz-box-shadow:0 0 20px 0 #000;-webkit-box-shadow:0 0 20px 0 #000;-o-box-shadow:0 0 20px 0 #000;-ms-box-shadow:0 0 20px 0 #000*/}
#isi_in2{width:1080px; text-align:center; border:0px solid #000; padding:0}
#isi_in3{width:1080px; text-align:center; border:0px solid #000; padding:0}
.isi_in_alt{ padding:12px;background:url(images/bgtrans.png) repeat #fff; }
#isi_kotak{ background:#fff}
#isi_kiri{ width:270px; text-align:center;border:0px solid #000}
#isi_kiri_alt{ width:810px; text-align:center;float:left; border:0px solid #000}
.isi_tengah{ text-align:center;border:0px solid #000; }
#isi_tengah_alt{ width:270px; text-align:center; float:left; border:0px solid #000; padding:0}
#isi_tengah_bg1{ width:100%; text-align:center; float:left; padding:10px;background:url(images/bgprod.png) no-repeat center}
#isi_tengah_bg2{ width:930px; text-align:center; float:left; padding:10px;background:#fff; border:3px solid #6caa2f;border-radius:0 50px 0 50px;-moz-border-radius:0 50px 0 50px;-webkit-border-radius:0 50px 0 50px;-o-border-radius:0 50px 0 50px;-ms-border-radius:0 50px 0 50px;}
.isi_kanan{ text-align:center;border:0px solid #000; }
#isi_kanan_alt{ width:270px; text-align:center;float:left; border:0px solid #000}
.accordion-sw{
	div {
		padding: 4px;
		border:0px solid #eee;
		.detail {
			background: #fff;
		}
		a {
			padding:0;
		}
		h3 {
			margin: 0;
			padding: 0;
			border:0;
			background:lighten($putih, 20%);
			text-align: left;
			span {
				color:#444;
				font:bold 16px "Open Sans";
				text-transform: capitalize;
			}
		}
	}
}
.kiri_kotak{ 
	border:1px solid #ddd; 
	@include border-radius(4px);
}
.kotak_outer{
	@include border-radius(4px);
	// @include tombol-transition(all 0.90s linear);	
	@extend %shared-tombol-shadow;
	margin: 0 auto; 
	padding: 0px; 
	border: 1px solid darken($putih, 10%); 
	background: darken($putih, 0); 
}
.kotak_inner{ 
	@include border-radius(4px);
	// @include tombol-transition(all 0.90s linear);	
	@extend %shared-tombol-shadow;
	margin: 0 auto; 
	padding: 8px; 
	border: 1px solid darken($putih, 10%); 
	background: darken($putih, 0); 
}
#bottom{width:100%; border:0px solid #000; /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#010101), to(#161616)); background: -webkit-linear-gradient(top, #010101, #161616); background: -moz-linear-gradient(top, #010101, #161616); background: -ms-linear-gradient(top, #010101, #161616); background: -o-linear-gradient(top, #010101, #161616);*/ margin:0px auto; padding:0px 0; /*position:fixed;*/}
#bottom1{margin:0; /*border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; -o-border-radius:5px; -ms-border-radius:5px; */ }
#bottom1{padding:0px 0; border:0px solid #f00; }
.bottom2{
	background: $second-color;
}
.bottom3{
	background: $first-color;
}
#search{border:1px solid #ccc;background:#fff;vertical-align:middle;border-radius:5px;-moz-border-radius:5px;-webkit-border-radius:5px;-o-border-radius:5px;-ms-border-radius:5px;margin:0 auto;padding:0}
.input-search{border:0px solid #fff;background:#fff; color:#666}
#cari{margin:0 auto;padding:0}
#topcari{background:#fff;border:1px solid #fff;-moz-border-radius:15px;-webkit-border-radius:15px;margin:0 auto;padding:0}
.topcari{background:transparent;border:2px solid #ddd;border-radius:4px;-moz-border-radius:4px;-webkit-border-radius:4px;-o-border-radius:4px;-ms-border-radius:4px;margin:0 auto;padding:0}
#gallery { }
ul.gallery { margin:0 auto; padding:0}
ul.gallery li { display: inline; float:left;margin:0; padding:0}
.clear{float:none;clear:both}
.banner{text-align:center; border:0px solid #f00; margin:0; padding:0; }
#admin_main_inner{text-align:center;border:1px solid #acacac;border-radius:5px;-moz-border-radius:5px;-webkit-border-radius:5px;margin:0 auto;padding:3px}
#admin_main_head{text-align:center;background:url(images/spacer.gif); opacity:1.0;height:50px;margin:0 auto;padding:0}
hr{ margin:0;padding:0; /*border:1px solid #999; -moz-box-shadow:0 0 1px #ccc; -webkit-box-shadow:0 0 1px #ccc*/ }
.copyright{font:normal 11px "Open Sans", "PT Sans", arial;color:#eee;}
.lwd{
	color:#eee;
	text-decoration:none;
	&:hover {
		color:#A9D9FF; 
		text-decoration:none
	}
}
.lwd_aktif{color:#A9D9FF;text-decoration:none}
.placeholder{color:#666}
.menubottom {
	color:#eee;
	text-decoration:none;
	text-transform:capitalize; 
	&:hover {
		color:#A9D9FF; 
		text-decoration:none		
	}
	li {
		margin:0 auto; 
		padding:5px 0; 
		list-style-type:none
	}
}
.mark {
	li {
		width:75px; float:left			
	}
}
.captcha { width:100px; height:35px; background:url(images/bgcaptcha.jpg) repeat; margin:5px; padding:5px; font:italic normal 25px georgia; color:#666; text-decoration:none; border:0px solid #000 }
.caption{padding:4px 0;border-radius:2px;-moz-border-radius:2px;-webkit-border-radius:2px;-o-border-radius:2px;-ms-border-radius:2px; /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f00), to(#9a0000)); background: -webkit-linear-gradient(left, #f00, #9a0000); background: -moz-linear-gradient(left, #f00, #9a0000); background: -ms-linear-gradient(left, #f00, #9a0000); background: -o-linear-gradient(left, #f00, #9a0000)*/}
.caption1{
	color: lighten($hitam, 40%); 
	font:normal 24px "Open Sans", "Times New Roman", Oswald, Raleway, Arial, "Dosis", Calibri, Cambria, "Hoefler Text", "Liberation Serif", Times, serif, "Cinzel Decorative"; 
	text-transform:capitalize; 
	text-decoration:none; 
	text-shadow:0px 0px 0px #ccc; 
	border:0px solid #000; 
	/*border-bottom:1px solid #ccc; border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px; -o-border-radius:10px; -ms-border-radius:10px;box-shadow:2px 2px 2px 2px #ddd; -moz-box-shadow:2px 2px 2px 2px #ddd; -webkit-box-shadow:2px 2px 2px 2px #ddd;*/}
.caption2{
	// color:#444; 
	font:normal 20px "Roboto", "Open Sans", Times, Oswald, Raleway, Cambria, "Hoefler Text", "Liberation Serif", "Times New Roman", 'serif'; 
	text-shadow:0px 0px 0px #666; 
	text-decoration:none; 
	border:0px solid #ccc; 
	border-radius:0; 
	-moz-border-radius:0; 
	-webkit-border-radius:0; 
	-o-border-radius:0; 
	-ms-border-radius:0; 
	/*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FAFAFA), to(#D6D6D6)); background: -webkit-linear-gradient(top, #FAFAFA, #D6D6D6); background: -moz-linear-gradient(top, #FAFAFA, #D6D6D6); background: -ms-linear-gradient(top, #FAFAFA, #D6D6D6); background: -o-linear-gradient(top, #FAFAFA, #D6D6D6)*/}
.caption3{
	// color: #444;
	font: normal 14px Roboto, "Open Sans", "sans-serif", Oswald, Raleway, "PT Sans", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif; 
	text-transform: capitalize; 
	text-decoration: none; 
	line-height: 20px;
	// text-shadow: 0px 0px 0px #ccc
}
/*.trans{background:url(images/bgtrans.png) repeat-y;text-align:left;-moz-border-radius:7px;-webkit-border-radius:7px;height:480px;margin:5px 0;padding:10px}*/
.trans1{background:url(images/bgtrans.png) repeat-y;text-align:left;-moz-border-radius:7px;-webkit-border-radius:7px;margin:5px 0;padding:5px}
.judul{
	font: bold 20px Oswald, "Open Sans", Raleway, "PT Sans", Arial, sans-serif, "Dosis", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", Helvetica; 
	color: #444;
	text-transform: capitalize;
	text-decoration: none;
	text-shadow: 0px 0px 0px #ccc;
	&:hover {
		color: lighten($hitam, 50%);
	}
}
.judul2{
	font:bold 14px Roboto, "Open Sans", Raleway, Oswald, "PT Sans", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", Helvetica, Arial, "Times New Roman", sans-serif; 
	color: lighten($hitam, 25%); 
	text-transform:capitalize;
	text-decoration:none;
	text-shadow:0 0 0px #fff;
	margin:0 auto;
	padding:0px 0;
	&:hover {
		color: lighten($hitam, 50%);
	}
}
.judul_inactive{
	font:normal 22px Roboto, "Open Sans", "PT Sans", Raleway, "Dosis", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform:capitalize;
	text-decoration:none;
	text-shadow:0px 0px 0px #ccc
}
.menu_admin{ margin:0 auto; padding:0 3px; font:12px arial;font-weight:700;/*line-height:20px;*/color:#3a3a3a;text-transform:capitalize;text-decoration:none; cursor:pointer}
.menu_admin:hover{color:#069; background:#fdfaca; -moz-box-shadow:0 0 3px #069; -moz-border-radius:3px;-webkit-box-shadow:0 0 3px #069; -webkit-border-radius:3px}
.pagi-nation{text-align:center;padding:12px 0}
.single-item-blog {
  /*width:200px;*/
  /*height:200px;*/
  /*background-color:#f3f3f3;*/
  /*margin: 20px;*/
  display:flex;
  align-items: top;
  justify-content:center;
  border-radius: 10px;
  color:#888;
  .judul-height {
  	height: 80px;
  }
}
.pagination{
  padding: 16px;
  &,
  *{
    user-select: none;
  }
  a{
    display:inline-block;
    padding:0 10px;
    cursor:pointer;
    &.disabled{
      opacity:.3;
      pointer-events: none;
      cursor:not-allowed;
    }
    &.current{
      background: lighten($putih, 25%);
    }
  }
}  
// button{}
.text-page{ 
	@include border-radius(0);
	// @include tombol-transition(all 0.90s linear);	
	@extend %shared-tombol-shadow;
	font:normal 13px "Open Sans", calibri;
	border:1px solid #eee;
	color:#666/*5D85B2*/;
	line-height:20px;
	text-decoration:none;
	background:#fff;
	margin:2px;
	padding:4px;
	&:hover {
		font:normal 13px "Open Sans", calibri;
		border:1px solid #eee;
		color:#eee;
		line-height:20px;
		text-decoration:none;
		background:#666;
		border-radius:0;
	}
}
// .text-page:hover{
// 	font:normal 13px "Open Sans", calibri;border:1px solid #eee;color:#eee;line-height:20px;text-decoration:none;background:#666;border-radius:0;
// 	-moz-border-radius:0;-webkit-border-radius:0;-o-border-radius:0;-ms-border-radius:0;box-shadow:0 0 1px 1px #ccc; -moz-box-shadow:0 0 1px 1px #ccc; -webkit-box-shadow:0 0 1px 1px #ccc; -o-box-shadow:0 0 1px 1px #ccc; -ms-box-shadow:0 0 1px 1px #ccc;
// }
.text-page-aktif {
	@include border-radius(0);
	@extend %shared-tombol-shadow;
	font:bold 13px "Open Sans", calibri;
	border:1px solid #eee;
	color:#eee;
	line-height:20px;
	text-decoration:none;
	background:#666;
	margin:2px;
	padding:4px;
}
input, textarea, select, fieldset{
	border-collapse:collapse;
	@include border-radius(4px);
	margin:0 auto;
	padding:4px
}
form {
	fieldset { 
		border: 0px solid #ddd;
		legend.sw{
			text-align: left;
			font:bold 14px 'Open Sans';
			font-style:italic; 
			text-transform:capitalize;
			margin:0 10px;
			padding:0; 
			border:0px solid #666; 
			width:auto
		}
	}
}
.tombol{color:#eee;text-transform:uppercase;text-decoration:none;border:1px solid #eee; background:#888; /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#268adf), to(#0358a1)); background: -webkit-linear-gradient(top, #268adf, #0358a1); background: -moz-linear-gradient(top, #268adf, #0358a1); background: -ms-linear-gradient(top, #268adf, #0358a1); background: -o-linear-gradient(top, #268adf, #0358a1);*/ border-radius:0;-moz-border-radius:0;-webkit-border-radius:0;-o-border-radius:0;-ms-border-radius:0;cursor:pointer;margin:0 auto;padding:6px; /*box-shadow:0px 0px 4px #aaa; -moz-box-shadow:0px 0px 4px #aaa; -webkit-box-shadow:0px 0px 4px #aaa; -o-box-shadow:0px 0px 4px #aaa; -ms-box-shadow:0px 0px 4px #aaa; */transition: all 0.3s linear; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -o-transition: all 0.3s linear; -ms-transition: all 0.3s linear}
.tombol2{color:#eee/*7a5f26*/;text-transform:capitalize;text-decoration:none;border:1px solid #71C0E4; background:#2696C9; -webkit-transform: skew(-30deg); -moz-transform: skew(-30deg); -o-transform: skew(-30deg); /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1783B1), to(#2EB1E9)); background: -webkit-linear-gradient(top, #1783B1, #2EB1E9); background: -moz-linear-gradient(top, #1783B1, #2EB1E9); background: -ms-linear-gradient(top, #1783B1, #2EB1E9); background: -o-linear-gradient(top, #1783B1, #2EB1E9);*/border-radius:0 ;-moz-border-radius:0 ;-webkit-border-radius:0 ;-o-border-radius:0 ;-ms-border-radius:0 ;cursor:pointer;margin:0 auto;padding:8px ; /*box-shadow:0px 0px 3px #999; -moz-box-shadow:0px 0px 3px #999; -webkit-box-shadow:0px 0px 3px #999; -o-box-shadow:0px 0px 3px #999; -ms-box-shadow:0px 0px 3px #999; */transition: all 0.3s linear; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -o-transition: all 0.3s linear; -ms-transition: all 0.3s linear}
.tombol3{ border:1px solid #fff; color:#eee; background:transparent/*transparent*/; padding:8px; transition: all 0.4s linear; -webkit-transition: all 0.4s linear; -moz-transition: all 0.4s linear; -o-transition: all 0.4s linear; -ms-transition: all 0.4s linear; cursor:pointer}
.tombol4{ background:transparent; padding:8px; text-decoration:none}
.tombol4:hover{ color:#444;background:#A9D9FF}
.tombol:hover, .tombol3:hover{border:1px solid #eee; color:#00317A;text-decoration:none; background:/*url(images/-bgmenu2-hov.-png) repeat-x*/ #C2AD80; /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#0358a1), to(#268adf)); background: -webkit-linear-gradient(top, #0358a1, #268adf); background: -moz-linear-gradient(top, #0358a1, #268adf); background: -ms-linear-gradient(top, #0358a1, #268adf); background: -o-linear-gradient(top, #0358a1, #268adf);*/ box-shadow:0 0 1px 1px #bbb; -moz-box-shadow:0 0 1px 1px #bbb; -webkit-box-shadow:0 0 1px 1px #bbb; -o-box-shadow:0 0 1px 1px #bbb; -ms-box-shadow:0 0 1px 1px #bbb}
.tombol2:hover{border:1px solid #eee; color:#00317A;text-decoration:none; background:/*url(images/-bgmenu2-hov.-png) repeat-x*/ #71C0E4; /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#0358a1), to(#268adf)); background: -webkit-linear-gradient(top, #0358a1, #268adf); background: -moz-linear-gradient(top, #0358a1, #268adf); background: -ms-linear-gradient(top, #0358a1, #268adf); background: -o-linear-gradient(top, #0358a1, #268adf);*/ box-shadow:0 0 1px 1px #bbb; -moz-box-shadow:0 0 1px 1px #bbb; -webkit-box-shadow:0 0 1px 1px #bbb; -o-box-shadow:0 0 1px 1px #bbb; -ms-box-shadow:0 0 1px 1px #bbb}
.tombol:disabled{background-color:#ccc;color:#fff;border:1px solid #b9b9b9;font-size:12px;font-family:tahoma;margin:1px auto;padding:1px inherit}
.input:disabled { background:#f0f0f0 }
.mordet{
	color:#102d24; 
	margin:0 auto;
	padding:0; 
	&:hover {
		text-decoration:none;
		color: lighten($first-color, 0);		
	}
}
.moredetail_inactive {
	color:#888/*#e97825#2185A9*/;
	text-decoration:none;
	text-transform:capitalize;
	line-height:20px;
	margin:0 auto;
	padding:0;
	&:hover {

	}
}
.moredetail{
	color:#18a6b2;
	text-decoration:none;
	&:hover {
		color:#1ED0DF		
	}
}
.bold{font-weight:bold;text-decoration:none;}
.thin{text-decoration:none;color:#933}
/*#menu{margin:0 auto; padding:0; height:40px; vertical-align:middle; width:950px; text-align:center; background:url(images/bgmenu.png) repeat-x; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px}*/
.block{
	display:block;
	border:1px solid $putih;
	text-align:center;
	&:hover {
		display:block;
		border:1px solid darken($merah, 0);
		text-align:center;
	}
}
/*.form_errorloc, #form_errorloc, #myform_errorloc{font:italic normal 12px "Source Sans Pro", "Segoe UI", "open sans", verdana;color:#f33;line-height:;text-align:left;padding:0px}*/
.warning{
	font-style:italic;
	color: darken($merah, 0%);
	margin:auto;
}
.input,.curve3{border:1px solid #ccc;line-height:16px;border-radius:4px;-moz-border-radius:4px;-webkit-border-radius:4px;margin:2px auto;padding:2px; transition:all 0.3s linear; -moz-transition:all 0.3s linear; -webkit-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear}
.input:hover, input:focus, textarea:focus {border:1px solid #a9d6f3; box-shadow:0 0 3px 3px #ccc; -moz-box-shadow:0 0 3px 3px #ccc; -webkit-box-shadow:0 0 3px 3px #ccc; -o-box-shadow:0 0 3px 3px #ccc; -ms-box-shadow:0 0 3px 3px #ccc}
tr {
	.blockrow {
		td {
			margin: 0;
			padding: 4px; 
			// height:60px; 
			// transition:all 0.3s linear; 
			// -moz-transition:all 0.3s linear; 
			// -webkit-transition:all 0.3s linear; 
			// -o-transition:all 0.3s linear; 
			// -ms-transition:all 0.3s linear;
		}
		&:hover {
			td {
				background-color: #f00;
				margin: 0;
				padding: 4px				
			}
		}
	}
}
div,img{behavior:url('iepngfix.htc');border:0}
.clock{color:#999; }
.login{background:url(images/transparan2.png) repeat-x;width:282px;height:134px;-moz-border-radius:10px;-webkit-border-radius:10px;margin:0 auto;padding:0}
#commentForm{width:500px}
#commentForm label{width:250px}
#commentForm label.error,#commentForm input.submit{margin-left:253px}
#signupForm{width:100%}
#signupForm label.error{margin-left:10px;width:auto;display:inline}
#newsletter_topics label.error{display:none;margin-left:103px}
.ui-autocomplete{max-height:100px;overflow-y:auto}
* html .ui-autocomplete{height:100px}
#result { color:#f00; background:#f0ffed; border:1px solid #336633; margin:2px; padding:2px; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; }
.news {
	color: lighten($hitam, 40%);
	text-align: left; 
	line-height: 20px; 				
	.table-alt{
		width:100%;
		tr {
			th, td {
				padding:4px;
				border-bottom:1px solid #eee;				
			}
		}
	}
}
.news2{
	color:#666;
	font:normal 11px verdana; 
	text-align: left
}
.news_alt{
	color:#444;
	font: normal 13px "Open Sans", Raleway, "PT Sans", "Segoe UI", "Trebuchet MS", Gotham, "Helvetica Neue", Arial, sans-serif, Helvetica; 
	text-align: left; 
	line-height: 20px; 
	&:hover {
		// color:#fff		
	}
}
.harga {
	color: lighten($hitam, 25%);
	// color: #BF1E2E;
}
.border, .border_inactive{
	@include border-radius(0);
	@include tombol-transition(all 0.90s linear);	
	margin:2%; 
	// background:<?=$bg;?>;
	// overflow:hidden;
	border:1px solid #ddd; 
	background: $putih; 
	text-decoration:none; 
	// display: flex;
	align-items: center;
	justify-content: center;
	/*box-shadow:0px 0px 2px #ddd; -moz-box-shadow:0px 0px 2px #ddd; -webkit-box-shadow:0px 0px 2px #ddd; -o-box-shadow:0px 0px 2px #ddd; -ms-box-shadow:0px 0px 2px #ddd;*/ 
	&:hover {
		border:1px solid darken($first-color, 0); 
		background: darken($putih, 0); 
		box-shadow: 0 0 4px 4px #ddd; 
	}
}
.kotak{
	@include border-radius(4px);
	border:1px solid #ddd; 
	background: $putih; 
	text-decoration:none;
	margin:4px;
	&:hover {
		border:1px solid darken($first-color, 0); 
		background: darken($putih, 0); 
		box-shadow: 0 0 4px 4px #ddd; 		
	}
}
// .border:hover,.kotak:hover{
// 	border:1px solid #43ADFF; 
// 	background:#fff; 
// 	box-shadow:0 0 0px 0px #bbb; 
// 	-moz-box-shadow:0 0 6px 2px #ccc; -webkit-box-shadow:0 0 6px 2px #ccc; -o-box-shadow:0 0 6px 2px #ccc; -ms-box-shadow:0 0 6px 2px #ccc
// }
.border_alt{border:1px solid #eee; background:transparent; text-decoration:none; border-radius:0; -moz-border-radius:0;-webkit-border-radius:0; -o-border-radius:0; -ms-border-radius:0; /*box-shadow:0px 0px 2px #ddd; -moz-box-shadow:0px 0px 2px #ddd; -webkit-box-shadow:0px 0px 2px #ddd; -o-box-shadow:0px 0px 2px #ddd; -ms-box-shadow:0px 0px 2px #ddd;*/ transition:all 0.3s linear; -moz-transition:all 0.3s linear; -webkit-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear}
.border_alt:hover{border:1px solid #D0F5F9; background:#F6F3EA; /*box-shadow:0 0 6px 2px #ccc; -moz-box-shadow:0 0 6px 2px #ccc; -webkit-box-shadow:0 0 6px 2px #ccc; -o-box-shadow:0 0 6px 2px #ccc; -ms-box-shadow:0 0 6px 2px #ccc*/}
.border img, .border_alt img {/*transform:scale(0.8);-moz-transform:scale(0.8);-webkit-transform:scale(0.8);-o-transform:scale(0.8);-ms-transform:scale(0.8);*/transition:all 0.4s ease;-moz-transition:all 0.4s ease;-webkit-transition:all 0.4s ease;-o-transition:all 0.4s ease;-ms-transition:all 0.4s ease; opacity:1.0}
.border img:hover, .border_alt img:hover { /*transform:scale(1.2) rotate(0deg);-moz-transform:scale(1.2) rotate(0deg);-webkit-transform:scale(1.2) rotate(0deg);-o-transform:scale(1.2) rotate(0deg);-ms-transform:scale(1.2) rotate(0deg);*/ transition:all 0.2s ease;-moz-transition:all 0.2s ease;-webkit-transition:all 0.2s ease;-o-transition:all 0.2s ease;-ms-transition:all 0.2s ease;}
.border_circle{ 
	@include border-radius(200px);
	@include tombol-transition(all 0.4s linear);	
	box-shadow:0 0 1px 1px $hitam;
	&:hover {
		box-shadow:0 0 4px 4px $hitam;		
	}
}
.kiri_hitam{ margin:0 auto; padding:7px; background:#3c3c3c; border-radius:7px; -moz-border-radius:7px; -webkit-border-radius:7px}
.kanan_abu{ margin:0 auto; padding:7px; background:#f3f3f3; border-radius:7px; -moz-border-radius:7px; -webkit-border-radius:7px}
#admin_main_outer,#admin_body{text-align:center;margin:0 auto;padding:3px; border:0px solid #000}
#admin_isi,#admin_isi2{margin:0 auto;padding:0}
/*#admin_isi2{text-align:center;margin:0 auto;padding:1px 0; width:950px}*/
ul.menu,h1,h2,h3,h4,h5,h6{margin:0 auto;padding:0}
.judul_kolom{color:#069;background:url(js/jq-ui/base/images/ui-bg_glass_75_dadada_1x400.png) repeat-x /*#dafbfe*/ ;font:bold 12px "Source Sans Pro", "Segoe UI", "open sans", verdana;text-transform:capitalize;text-decoration:none;}
.odd{background:#fff}
.even{background:#eee}
.putihlink{font:normal 12px tahoma;color:#fff; text-decoration:none}
.putihlink:hover{color:#f90}
.putih{
	color: darken($putih, 4%); 
	text-decoration:none; 
	text-shadow:0 0 0px #888
}
.white{color:#888;text-shadow:0px 0px 0px #888;}
.yes {
	// font: 12px arial;
	font-style: italic;
	color: darken(#5CB85C, 0%); 
}
.date, .no{
	// font:12px arial;
	color: darken(#D9534F, 0%); 
	line-height:16px; 
	text-decoration:none;
	text-align:justify;
	margin:0 auto;
	padding:0; 
	font-style:italic;
}
.kat{
	color: lighten($hitam, 25%);
	text-transform:capitalize;
	display:block;
	margin:0 auto;
	&:hover {
		text-decoration:none;
		color: darken($merah, 0);
		// background:url('../../images/shape.png') no-repeat ; 
	}
}
.kat_aktif{
	@include border-radius(4px);
	font-weight: bold;
	text-transform:capitalize;
	text-decoration:none;
	display:block;
	margin:0 auto;
	color: darken($merah, 0);
}
.kolom{font:normal 12px "Source Sans Pro", "Segoe UI", "open sans", verdana;color:#222;line-height:17px;text-align:left;-moz-column-count:2;-moz-column-gap:1em;-moz-column-rule:1px dotted #000;-webkit-column-count:2;-webkit-column-gap:1em;-webkit-column-rule:1px dotted #000;margin:0 auto;padding:2px 5px}
.text,.text-content,label{margin:0 auto;}
#loading, .notification{display:none;/*top:0;left:0;font-size:24px;position:fixed;color:#FFF;background-color:red;font:;text-align:center;padding:8px*/}
.email {
	&:hover{
		text-decoration:none; 
	}
}
/*.email:hover { text-decoration:underline }*/
.garis { 
	border-bottom: 1px solid #eee; 
	margin:0 auto; 
}
/*#panel { height:650px }*/
/*tr.selected { background:#fbc7c7 }*/
.action a { font:normal 12px "Source Sans Pro", "Segoe UI", "open sans", verdana; text-decoration:none }
.action a:hover { color:#c03 }
.bgintro { background:url(images/bgintro.png) repeat-x }
.col_nama { width:200px }
.col_ktrg { width:500px }
.col_img { width:60px }
.col_pub { width:80px }
.bnrpsn1{ border:1px solid #eee; background:url(images/bgrss.png) repeat-x;border-radius:5px;-moz-border-radius:5px;-webkit-border-radius:5px; margin:5px; padding:5px 0}
a:link{}
.x-large{font-size:x-large}
#side-menu li a{ color:#999}
#side-menu li a:hover { color:#ccc}
.textalert a{ color:#EEE; font-weight:bold; border-right: dashed 1px #EEE}
.textalert sup{ padding:4px 8px; border-radius:12px; background-color:#F30}

.backpro{float:left;width:33%; text-align: left; margin-top:10px}
.emptypro{float:left;width:33%; text-align:center; margin-top:10px}
.lanjutpro{float:left;width:33%; text-align: right; margin-top:10px}

.trak{ font:normal 13px "Open Sans", Raleway, "PT Sans", "Times New Roman"; padding:4px; border:solid 1px #ddd; color:#222;}
.jdcekresi{ font-weight:bold; color:#222; text-transform:uppercase}
.subtrak{ font:bold 13px Tahoma; padding:6px 15px; color:#fff; background-color:#E50000; border:1px solid #8E3B3D}
.subtrak:hover{ background-color:#C10E0E; border:1px solid #8E3B3D}
.contrak{ font:inherit/*bold 13px Tahoma*/; padding:4px 12px; color:#fff; background-color:#0E0E0E; border:1px solid #010101}
.contrak:hover{ background-color:#353535; border:1px solid #282828}
.shopna{ font:normal 18px "Times New Roman"; color:#1c1410; text-transform:capitalize; text-decoration:underline; font-style:italic}
.shopna:hover{ color:#000000; text-decoration:none}
.rm{ font:normal 18px "Times New Roman"; color:#1c1410; text-transform:capitalize; text-decoration:underline; font-style:italic}
.rm:hover{ color:#000000; text-decoration:none}
.readm{ border:solid 1px #c0c0c0; font-size:12px; font-style:italic; color:#2C2C2C; background-color:#FFF; width:10%; padding:1px 8px; text-decoration:none}
.readm:hover{ border:solid 1px #B3B3B3; background-color:#B3B3B3; padding:1px 8px;
	-webkit-transition: all 0.4s linear; 
	-moz-transition: all 0.4s linear; 
	-o-transition: all 0.4s linear; 
	-ms-transition: all 0.4s linear; 
	transition: all 0.4s linear;
}

/*.isfield span{ display:none}*/
.formkontak{ width:100%; font-size:14px; font-family:"Times New Roman", serif;} 
.isfield{ 
	clear:both; 
	margin:auto; 
	padding:5px 0px; 
	margin:7px 0px;
	label {
		color:#333; 
		float:left;
		padding:4px; 
		width:25%; 
		text-align: left;		
	}
	input, textarea {
		padding:4px; 
		float:left; 
		width:50%;
	}
	span {
		text-align: left;
	}
}
.tengah{ margin:auto; text-align: center;}
.pinggir{ margin:auto; text-align: left;}
/*.isfield button{ font-size:14px; color:#FFF; background-color:#e50000; padding:4px 8px}
.isfield button:hover{ background-color:#000}*/

.isfield select{ padding:5px; float:left; margin-left:0px}
.isfield label.nextform{ padding:5px 0px; width:70%; text-align:left; margin-right:10px}
.pinggir input{ text-align:left; margin:0px; padding:0px; width:auto}

.headtab{ border:solid 1px #666666; width:100%; font-size:13px}
.headtab .head{ background-color:#CCC}
.headtab tr{ border:solid 1px #999999;}

.kiritran{ float:left; /*width:77%;*/font-family:"Times New Roman", serif;}
.kanantran{ float:left; /*width:19%;*/ margin-left:10px; padding:10px;font-family:"Times New Roman", serif;}

.divkonfblm{border:solid 1px #D9534F; padding:8px; text-transform:capitalize}
.butkonfblm{ width:100%; background-color: #D9534F; padding:12px 0px; font-size:14px; color:#FFF; text-decoration:none}
.butkonfblm:hover{ background-color:#DE6764; color:#FFF}

.divkonfsdh{border:solid 1px #5CB85C; text-transform:capitalize; padding:7px}
.divkonfsdh span{ color:#390}
.butkonf{ width:100%; background-color: #5CB85C/*390*/; padding:12px 0px; font-size:14px; color:#FFF; text-decoration:none}
.butkonf:hover{ background-color:#6EC06E; color:#FFF}

.divkirim{border:solid 1px #390; text-transform:capitalize; padding:7px}
.divkirim span{ color:#7F282A}

.div-member{ 
	font-family:"Times New Roman", serif; 
	font-size:12px; 
	text-transform:capitalize
}
.div-member h3{ 
	font-size:20px; 
	text-transform:capitalize; 
	color:#252C7A
}
/*.div-member a{ font-size:12px; text-transform:capitalize; text-decoration:none; color:#252C7A}
.div-member a:hover{ color:#404BC5}*/

.navmem{ width:100%}
.navmem sup{ padding:4px ; color:#FFF; background-color:#F30; border-radius:10px}
.navmem ul { margin:0px; padding-left:0px; list-style:none}
.navmem li{ padding:4px}
.navmem li a{ color:#444; border-bottom:dashed 1px #ddd}
.navmem li.active a{ color:#F30; background-color:#ddd;padding:4px}

@-webkit-keyframes backgroundScroll {
from {background-position: 0 0;}
to {background-position: -400px 0;}
}
 
@keyframes backgroundScroll {
from {background-position: 0 0;}
to {background-position: -400px 0;}
}
/*agung css*/
.left1{ /*width: 350px ;float: left; margin-left: 15px*/}
.left2{
/*	background-image: url("tempimages/left2.jpg"); background-repeat: no-repeat; margin-top: 0;
	width: 372px; height: 532px; float: left; z-index:1;*/
}
.left3 {
	font-family: 'Open Sans', sans-serif; font-size: 24px; color: #9D9393; padding: 20px; border:solid 4px #0C5FE7; right: 0px; margin-top: 0%; margin-bottom: 0%; z-index:0;
/*	width: 40%; position: absolute;*/
}
.nomor{ 
	font-family: 'Open Sans', sans-serif; font-size: 80px; -webkit-text-stroke: 9px #ccc; display: inline; float: left;
}
.kethome{
	font:normal 16px 'Open Sans', sans-serif; margin-left: 0%; color: #9D9393;
/*	font-family: 'Open Sans', sans-serif;*/
}
.headline{font:bold 24px Arial, Helvetica, sans-serif; color: #444; background:#fff; -webkit-text-stroke: 0px #f00; padding:12px}
.borderh{width: 100%; background-color: #ddd; height: 1px; margin:-16px 0 28px 0}
.butblue{font-family: 'Open Sans', sans-serif; font-size: 14px; color: #FFF; background-color: #042d70; padding: 10px 20px; border:none; text-transform: uppercase; margin-top: 0px;}
.butblue:hover{color: #000; background-color: #CCC;}
.sh-compare{ margin:0 0 -28px 0; padding:4px; background:url('../../images/bgtrans1.png') repeat; float:right; position:relative; z-index:2}
.sh-compare:hover{ display:block; }
.sh-buy{ margin:0 24px -24px 0; padding:4px; background:url('../../images/bgtrans1.png') repeat; float:right; position:relative; z-index:2}
.sh-buy:hover{ display:block; }
.sh-date{ margin:0 0 -24px 0; padding:4px; background:url('../../images/bgtrans1.png') repeat; float:left; position:relative; z-index:2}
.sh-date:hover{ display:block; }
.sh-delete{ margin:0 0 -24px 0; padding:4px; background:url('../../images/bgtrans1.png') repeat; float:right; position:relative; z-index:2}
.sh-delete:hover{ display:block; }
.cf { position:relative; height:320px; margin:0 auto;}
.cf img { position:absolute; left:0; -webkit-transition: opacity .4s ease-in-out; -moz-transition: opacity .4s ease-in-out; -o-transition: opacity .4s ease-in-out; transition: opacity .4s ease-in-out;}
.cf img.top:hover { opacity:0;}

/*dot scroll*/
#nav-section-home { position: fixed; top:320px; right: 8px; padding: 5px; border-radius: 10px; background: #00317A; z-index: 5; border:1px solid #ddd }
#nav-section-home li { display: block; margin-bottom: 5px; }
#nav-section-home li:last-child { margin-bottom: 0px; }
#nav-section-home li .dot { background: #fff; border:2px solid #fff; border-radius: 100em; width: 12.5px; height: 12.5px; transition: all 0.2s linear; -o-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -webkit-transition: all 0.2s linear; }
#nav-section-home .active .dot { background: #888; transition: all 0.2s linear; -o-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -webkit-transition: all 0.2s linear; }

/*jajaran genjang*/
.jargen{border-top:0; border-right:0; border-bottom:1px solid #ddd; border-left:0}
/*paralax*/
.img_prlx{ background-attachment:fixed; background-position:center; height:560px}

#demo_container {
	margin:0px auto; 
	padding:0px 0px; 
	border:0px solid #f00; 
}
.menuweb_aktif {
	color:#eee; 
	// background:url(images/-icon_arrow_menu.png) no-repeat center top ; 
	border-radius:0px 0px 0 0; 
}
ul.sm-simple {
	li {
		.underscore_active {
			background:#B9DBFE; 
			height:2px; 
			margin:0; 
			box-shadow:0 0 0 #ddd
		}
		.underscore_inactive {
			background:#fff; 
			height:2px; 
			margin:0; 
			box-shadow:0 0 0 #ddd
		}
		a { 
			color:#444;
			padding:8px 12px; 
			font:normal 13px 'Open Sans'; 
		}
	}
}
.accordion-list {
	margin:0; 
	padding:0; 
	li {
		list-style-type:none; 
		border-bottom:0px solid #eee; 		
		a {
			display:block; 
			padding:4px;
			span {
				display:block; 				
			}			
		}
	}
}
.header{
	top: 0;
	box-sizing: border-box;
	width: 100%;
	position: fixed;
	img {
		max-height:40px; 
		padding:4px; 
		float:left
	}
}
.footer{
	bottom: 0;
	box-sizing: border-box;
	width: 100%;
	position: fixed;
}
.my-account {
	float:right; 
	position:fixed; 
	top:0; 
	right:0; 
	padding:4px; 
	z-index:540
}
.note {
	position:fixed; 
	top:0; 
	z-index:540; 
	width:100%;
	text-align: center;
}
ul.socmed-bottom {
	margin:0; 
	li {
		margin:0; padding:4px 0; list-style-type:none; float:left;
		div {
			padding:0px; border-radius:4px
		}
	}
}
.page-header {  /*background: #1abc9c;  */}
.page-logo {  max-width: 100px;  height : auto;}
.pull-left { float: left; }
.pull-right { float: right; }
/*.menu {  overflow: hidden;  list-style: none;}
.menu li {  float: left;  font-family: sans-serif;  margin-left: 1em;}
.menu li a {  color: #fff;  text-decoration: none;}
.page-content {  max-width: 600px;  margin-left: auto;  margin-right: auto;  margin-top: 160px;}
p {  font-family: sans-serif;  font-size: 2em;  color: #999;  line-height: 1.3em;}*/
.page-header {  
	padding: 0em;  
	overflow: hidden;  
	border-bottom: 0px solid #16a085;
	position: fixed;  
	left: 0;  
	top: 0;  
	width: 100%;  
	box-sizing: border-box;  
	-webkit-transition: top 0.6s;  
	-moz-transition: top 0.6s;  
	transition: top 0.6s;
}
.page-header.off-canvas { top: -0px; }
.page-header.fixed {  top: 0;  z-index: 10;}
#cart-flying-div {
	#draggable {
		position:fixed; 
		right:16px; 
		top:80px; 
		z-index:520; 
		border:2px solid #444; 
		padding:4px; 
		@include border-radius(8px);
	}
}
.tab-content{
	div{
		div.detail{
			padding:24px;
		}
	}
}
// .bullet-info{
// 	@include border-radius(20px);
// 	padding:4px 8px; 
// 	color:#eee;
// 	background:#D9534F; 
// 	position:relative; 
// 	z-index:10; 
// 	left:0px;
// }
.badge-note, .bullet-info {
	// @extend %shared-text-page;
	@include border-radius(20px);
	color: darken($putih, 5%); 
	background: darken($merah, 0%); 
	padding: 4px 8px; 
}
.stock {
	.sedia {
		@include border-radius(4px);
		padding:4px; 
		background: darken($second-color, 0); 
		z-index:10;
		font-size: smaller;
	}
	.kosong {
		@include border-radius(4px);
		padding:4px; 
		background: darken($merah, 0); 
		z-index:10;
		font-size: smaller;
	}
	.sukses {
		@include border-radius(4px);
		padding:4px; 
		background: darken($hijau, 0); 
		z-index:10;
		font-size: smaller;
	}
}
#accordion { 
	div {
		h3 {
			a {
				font:normal 12px "open sans"; 
				text-transform:capitalize; 
				text-align: left;
				&:hover {
					color: darken($putih, 0);
				}				
			}
		}
	}
} 
.katcover { 
	@include border-radius(4px);
	border:1px solid #ccc; 
	text-align: left; 
	margin:10px 0 0 0; 
	padding:0 
} 
.cat { 
	margin:0; 
	padding:5px;
	li {
		margin:0 auto; 
		padding:2px 0; 
		text-align: left; 
		list-style-type:none; 
		border-bottom:1px solid #ccc
	}
} 
.marquee, .marquee-with-options, .marquee-vert-before, .marquee-vert {
	overflow: hidden;
	/*width: 100%;border:1px solid #ccc;*/
}
#button-wa {
	position: fixed; bottom: 4%; right: 2%; 
}
.main-menu {
	position:relative;/*z-index:9999;*/
	width:auto;	
	float: right;
	ul {
		width:12em; 
		/* fixed width only please - you can use the "subMenusMinWidth"/"subMenusMaxWidth" script options to override this if you like */
	}
}
ul#main-menu-alt {
	float: right;
}
.doku {
	padding: 12px;
}
.pdf {
	border: 0 solid #eee; 		
	div {
		border: 0 solid #eee; 		
	}
  /*div {margin:; padding:; border:1px solid #eee; font:normal 12px Arial, Helvetica, sans-serif; line-height:20px}*/
  /*table.pdf tr td{ border-bottom:1px solid #eee; padding:8px; margin:}*/
  /*p{ }*/
	// font:normal 12px Arial, Helvetica, sans-serif; 
	table {
		tr {
			td { 
				border: 0 solid #eee; 
				padding: 4px; 
			}				
		} 	
	} 
}
.shadetabs {
	li {
		a {
			// background:#0E88AE;
			&:hover {
				color: lighten($hitam, 0);
				// background-color: darken($second-color, 0);
			}
			.selected {
				color: lighten($hitam, 0);
			}
		}
	}
}
.capitalize {
	text-transform: capitalize;
}
.logo-seller {
	width: 60px;
}